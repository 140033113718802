var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "lesson52-container" }, [
    _vm.pageType === 0
      ? _c(
          "div",
          { staticClass: "display-page" },
          [
            _vm._l(_vm.componentsList, function (item, key, index) {
              return [
                _c(
                  "keep-alive",
                  { key: index + "keep" },
                  [
                    _c(
                      "transition",
                      { key: index, attrs: { name: "slide-fade" } },
                      [
                        index + 1 === _vm.pageNum
                          ? _c(item, { key: index, tag: "component" })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            }),
          ],
          2
        )
      : _vm._e(),
    _vm.pageType === 1
      ? _c("div", { staticClass: "display-page" }, [_c("SyllabusPageB")], 1)
      : _vm._e(),
    _vm.pageType === 2
      ? _c("div", { staticClass: "display-page test" }, [_c("WhiteBoard")], 1)
      : _vm._e(),
    _vm.globalWhiteB
      ? _c(
          "div",
          { staticClass: "whiteBoard-box" },
          [
            _c("WhiteBoard", {
              attrs: { canvasHeight: _vm.cvHeight, canvasWidth: _vm.cvWidth },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }