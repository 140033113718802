<template>
  <div class="game-container">
    <StrokePath
      :firstAnimalInfo="firstAnimalInfo"
      :secondAnimalInfo="secondAnimalInfo"
      :puzzleBgImg="puzzleBgImg"
      :buttonList="buttonList"
      :puzzleItemList="puzzleItemList"
      :strokeStepsArr="strokeStepsArr"
      @avatarMove="handleMove"
      @getTarget="handleGetTarget"
      @firstStep="handleFirstStep"
      @initGame="handleInit"
      :lessonNum="lessonNum"
    />
  </div>
</template>

<script>
import StrokePath from "@/components/Course/GamePage/StrokePathGame";
export default {
  data() {
    return {
      lessonNum: 52,
      strokeStepsArr: [7, 7],
      puzzleItemList: [
      [
          { type: 0, isShow: false, index: 1 },
          { type: 2, isShow: false, index: null },
          { type: 1, isShow: false, index: 2 },
          { type: 1, isShow: false, index: 1 },
        ],
        [
          { type: 0, isShow: false, index: 2 },
          { type: 2, isShow: false, index: null },
          { type: 1, isShow: false, index: 3 },
          { type: 2, isShow: false, index: null },
        ],
        [
          { type: 0, isShow: false, index: 3 },
          { type: 2, isShow: false, index: 4 },
          { type: 1, isShow: false, index: 4 },
          { type: 1, isShow: false, index: 5 },
        ],
        [
          { type: 0, isShow: false, index: 4 },
          { type: 0, isShow: false, index: 5 },
          { type: 0, isShow: false, index: 6 },
          { type: 1, isShow: false, index: 6 },
        ],
        [
          { type: 2, isShow: false, index: null },
          { type: 2, isShow: false, index: null },
          { type: 0, isShow: false, index: 7 },
          { type: 1, isShow: false, index: 7 },
        ],
      ],
      puzzleBgImg: require("@/assets/img/16-GAMES/G18-maze2/lesson-52/background.svg"),
      firstAnimalInfo: {
        img: require("@/assets/img/16-GAMES/G18-maze2/lesson-52/maze-bear.svg"),
        finalImg: require("@/assets/img/16-GAMES/G18-maze2/lesson-52/maze-bear-honey.svg"),
        firstStrokeImgList: [
        require("@/assets/img/16-GAMES/G18-maze2/lesson-52/xiegou-1.svg"),
          require("@/assets/img/16-GAMES/G18-maze2/lesson-52/xiegou-2.svg"),
          require("@/assets/img/16-GAMES/G18-maze2/lesson-52/xiegou-3.svg"),
          require("@/assets/img/16-GAMES/G18-maze2/lesson-52/xiegou-4.svg"),
          require("@/assets/img/16-GAMES/G18-maze2/lesson-52/xiegou-5.svg"),
          require("@/assets/img/16-GAMES/G18-maze2/lesson-52/xiegou-6.svg"),
          require("@/assets/img/16-GAMES/G18-maze2/lesson-52/xiegou-7.svg"),
          
        ],
      },
      secondAnimalInfo: {
        img: require("@/assets/img/16-GAMES/G18-maze2/lesson-52/maze-dog.svg"),
        finalImg: require("@/assets/img/16-GAMES/G18-maze2/lesson-52/maze-dog-bone.svg"),
        secondStrokeImgList: [
        require("@/assets/img/16-GAMES/G18-maze2/lesson-52/wogou-1.svg"),
          require("@/assets/img/16-GAMES/G18-maze2/lesson-52/wogou-2.svg"),
          require("@/assets/img/16-GAMES/G18-maze2/lesson-52/wogou-3.svg"),
          require("@/assets/img/16-GAMES/G18-maze2/lesson-52/wogou-4.svg"),
          require("@/assets/img/16-GAMES/G18-maze2/lesson-52/wogou-5.svg"),
          require("@/assets/img/16-GAMES/G18-maze2/lesson-52/wogou-6.svg"),
          require("@/assets/img/16-GAMES/G18-maze2/lesson-52/wogou-7.svg"),
        ],
      },
      buttonList: [
        {
          image: require("@/assets/img/16-GAMES/G18-maze2/lesson-52/maze-bear.svg"),
          isPuzzlePage: true,
          startNumberRange: 1,
          endNumberRange: 3,
        },
        {
          image: require("@/assets/img/16-GAMES/G18-maze2/lesson-52/maze-dog.svg"),
          isPuzzlePage: true,
          startNumberRange: 2,
          endNumberRange: 4,
        },
      ],
    };
  },
  components: {
    StrokePath,
  },
  methods: {
    handleMove(indexObj) {
      const { lastRowIndex, lastColIndex, currentRowIndex, currentColIndex } =
        indexObj;
      console.log(lastRowIndex, lastColIndex, currentRowIndex, currentColIndex);
      this.puzzleItemList[lastRowIndex][lastColIndex].isShow =
        !this.puzzleItemList[lastRowIndex][lastColIndex].isShow;
      this.puzzleItemList[currentRowIndex][currentColIndex].isShow =
        !this.puzzleItemList[currentRowIndex][currentColIndex].isShow;
    },
    handleFirstStep(index) {
      if (!index) {
        if (this.lessonNum === 52) {
          this.puzzleItemList[0][0].isShow = true;
        } else {
          this.puzzleItemList[0][0].isShow = true;
        }
      } else {
        if (this.lessonNum === 52) {
          this.puzzleItemList[0][3].isShow = true;
        } else {
          this.puzzleItemList[0][0].isShow = true;
        }
      }
    },
    handleGetTarget(obj) {
      const { currentRowIndex: row, currentColIndex: col } = obj;
      console.log(row, col);
      this.puzzleItemList[row][col].isGetTarget = true;
    },
    handleInit() {
      this.puzzleItemList.forEach((item) => {
        item.forEach((ele) => {
          ele.isShow = false;
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>