<template>
  <div class="game-container">
    <SpeakingByTurntableGame :gameInfo="gameInfo" :optionsList="optionsList" />
  </div>
</template>

<script>
import SpeakingByTurntableGame from "@/components/Course/GamePage/SpeakingByTurntableGame";
export default {
  data() {
    return {
      gameInfo: {
        bgImg: require("@/assets/img/03-Backgrounds/level-1-chapter-7-background-blue.svg"),
        // titleHanzi: "……们",
        // titlePinyin: "men",
        turntableImg: require("@/assets/img/16-GAMES/G57-turntable/lesson-52-circle.svg"),
        needleImg:require("@/assets/img/16-GAMES/G57-turntable/lesson-52-spinner.svg"),
      },
      optionsList: [
        {
          pinyin: "wǒ",
          hanzi: "我",
          index: 2,
        },
        {
          pinyin: "nǐmen",
          hanzi: "你们",
          index: 1,
        },
        {
          pinyin: "nǐ",
          hanzi: "你",
          index: 0,
        },
        {
          pinyin: "wǒmen",
          hanzi: "我们",
          index: 3,
        },
      ],
    };
  },
  components: {
    SpeakingByTurntableGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>











