<template>
  <div class="game-container layout-border">
    <FindStroke
      :bgImg="bgImg"
      :gameIndex="gameIndex"
      :strokeImgList="strokeImgList"
      :strockNotFoundImage="strockNotFoundImage"
      :strockFoundImage="strockFoundImage"
    />
  </div>
</template>

<script>
import FindStroke from "@/components/Course/GamePage/FindStrokeGame";
export default {
  data() {
    return {
      bgImg: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-52/background-space.svg"),
      gameIndex: 52,
      strokeImgList: [
        {
          notFind: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-52/stroke-constellation.svg"),
          find: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-52/stroke-constellation-found.svg"),
        },
        {
          notFind: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-52/stroke-left-leg.svg"),
          find: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-52/stroke-left-leg-found.svg"),
        },
        {
          notFind: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-52/stroke-right-leg.svg"),
          find: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-52/stroke-right-leg-found.svg"),
        },
        {
          notFind: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-52/stroke-planet.svg"),
          find: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-52/stroke-planet-found.svg"),
        },
        {
          notFind: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-52/stroke-cable.svg"),
          find: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-52/stroke-cable-found.svg"),
        },
      ],
      strockNotFoundImage: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-52/stroke-xiegou-pending.svg"),
      strockFoundImage: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-52/stroke-xiegou-found.svg"),
    };
  },
  components: {
    FindStroke,
  },
};
</script>

<style lang="scss" scoped></style>
