<template>
  <div class="game-container">
    <SpeakingSentence
      :buttonList="buttonList"
      :gameList="gameList"
      @initGame="initGame"
      @showThirdImg="showThirdImg"
      :isThreeImg="true"
      :isBlueBackground="true"
    />
  </div>
</template>

<script>
import SpeakingSentence from "@/components/Course/CoursePage/SpeakingSentence";
export default {
  data() {
    return {
      originGameList: [],
      gameList: [
        {
          bgImg: require(`@/assets/img/16-GAMES/G28-click-reveal/lesson-52/image-1-1.svg`),
          secondImg: require(`@/assets/img/16-GAMES/G28-click-reveal/lesson-52/image-1-2.svg`),
          thirdImg: require(`@/assets/img/16-GAMES/G28-click-reveal/lesson-52/image-1-3.svg`),
        },
        {
          bgImg: require(`@/assets/img/16-GAMES/G28-click-reveal/lesson-52/image-2-1.svg`),
          secondImg: require(`@/assets/img/16-GAMES/G28-click-reveal/lesson-52/image-2-2.svg`),
          thirdImg: require(`@/assets/img/16-GAMES/G28-click-reveal/lesson-52/image-2-3.svg`),
        },
        {
          bgImg: require(`@/assets/img/16-GAMES/G28-click-reveal/lesson-52/image-3-1.svg`),
          secondImg: require(`@/assets/img/16-GAMES/G28-click-reveal/lesson-52/image-3-2.svg`),
          thirdImg: require(`@/assets/img/16-GAMES/G28-click-reveal/lesson-52/image-3-3.svg`),
        },
      ],
      buttonList: [
        {
          image: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-52/menu-1.svg"),
          startNumberRange: 1,
          endNumberRange: 1,
        },
        {
          image: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-52/menu-2.svg"),
          startNumberRange: 2,
          endNumberRange: 2,
        },
        {
          image: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-52/menu-3.svg"),
          startNumberRange: 3,
          endNumberRange: 3,
        },
      ],
    };
  },
  created() {
    this.originGameList = JSON.parse(JSON.stringify(this.gameList));
  },
  components: {
    SpeakingSentence,
  },
  methods: {
    initGame() {
      this.gameList.forEach((item, index) => {
        item.isShowSentence = false;
        item.bgImg = this.originGameList[index].bgImg;
      });
    },
    showThirdImg(index, clickIndex) {
      console.log(clickIndex);
      if (clickIndex === 1) {
        this.gameList[index - 1].bgImg = this.gameList[index - 1].secondImg;
      } else {
        this.gameList[index - 1].bgImg = this.gameList[index - 1].thirdImg;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
  cursor: pointer;
}
</style>